<template>
  <div id="invoice-list" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-5 mb-2">Rango de fechas</h6>
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
      </div>
      <!--Filter by product-->
      <div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
            <h6 class="mt-5 mb-2">Selecciona producto</h6>
            <v-select label="name" :options="products" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="product" class="mb-4 md:mb-0" name="local" id="local"/>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <vs-table
        v-model="selected"
        pagination
        max-items="20"
        search
        stripe
        :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportDetail">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar Detalle</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="doc">Nro Doc.</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th>Fecha</vs-th>
        <vs-th>Hora</vs-th>
        <vs-th sort-key="motive">Motivo</vs-th>
        <vs-th sort-key="warehouse">Almacén</vs-th>
        <vs-th sort-key="stock">Stock previo</vs-th>
        <vs-th sort-key="quantity">Cantidad</vs-th>
        <vs-th sort-key="price">Precio</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <!--Product name-->
          <vs-td>
            <p>{{ tr.product.name }}</p>
          </vs-td>

          <!--Product sku-->
          <vs-td>
            <p>{{ tr.product.sku }}</p>
          </vs-td>

          <!--Type doc-->
          <vs-td>
            <p v-if="tr.type === 'invoice' || tr.type === 'sale' || tr.type === 'creditNoteInvoice'">
              {{ tr.document }}</p>
            <p v-else>{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>
          <!--End-->

          <vs-td>
            <p> {{ getType(tr.type) }}</p>
          </vs-td>

          <vs-td>
            <p> {{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p> {{ tr.createdAt | time(true) }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.motive }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.warehouse.name }}</p>
          </vs-td>

          <!--Old stock-->
          <vs-td>
            <p>{{ tr.oldStock }}</p>
          </vs-td>

          <!--Quantity-->
          <vs-td
              v-if="tr.type === 'transferOut' || tr.type === 'sale' || tr.type ==='creditNoteInvoice' || tr.type === 'out'">
            <p>{{ tr.quantity * -1 }}</p>
          </vs-td>
          <vs-td v-else>
            <p>{{ tr.quantity }}</p>
          </vs-td>

          <vs-td v-if="tr.currency">
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency.code}`
                }).format(tr.cost - tr.discount)
              }}</p>
          </vs-td>
          <vs-td v-else>
            <p>PEN 0</p>
          </vs-td>

        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

import { db, Timestamp } from '@/firebase/firebaseConfig'

const _ = require('lodash')

import { es } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 15,
      isMounted: false,
      list: [],
      sales: [],
      invoices: [],
      products: [],
      initProgress: false,
      warehouses: [],
      startDate: null,
      endDate: null,
      product: {},
      es: es
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadProducts()
      await this.loadMovements()
      await this.loadWarehouses()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    /**
     * Exports to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        list.forEach((i) => {
          i.doc = (i.type === 'invoice' || i.type === 'sale') ? i.document : i.id.substr(0, 6).toUpperCase()
          i.type = this.getType(i.type)
          i.date = this.$options.filters.date(i.createdAt, true)
          i.hour = this.$options.filters.time(i.createdAt, true)
          i.warehouse = i.warehouse.name
          i.name = i.product.name
          i.sku = i.product.sku
        })
        const headerVal = ['name', 'sku', 'doc', 'type', 'date', 'hour', 'motive', 'warehouse', 'quantity']
        const headerTitle = ['Nombre', 'SKU', 'Documento', 'Tipo', 'Fecha', 'Hora', 'Motivo', 'Local', 'Cantidad']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de movimientos',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    /**
     * Export detail
     */
    exportDetail () {
      import('@/vendor/Export2Excel').then(excel => {
        const newList = []
        this.products.forEach((p) => {
          p.brand = p.brand.name
          p.sales = 0
          p.invoices = 0
          p.stock = 0
          let salesAdded = ''

          // Sales
          this.sales.forEach((lp) => {
            if (p.sku.trim().toLowerCase() === lp.sku.trim().toLowerCase()) {
              if (!salesAdded.includes(lp.doc)) {
                p.sales += lp.quantity
                salesAdded += lp.doc + '/'
              }
            }
          })

          // Invoices
          this.invoices.forEach((ip) => {
            if (p.id.trim().toLowerCase() === ip.id.trim().toLowerCase()) {
              p.invoices += ip.quantity
            }
          })

          p.stock = p.invoices - p.sales
          newList.push(p)
        })
        const headerVal = ['name', 'sku', 'brand', 'invoices', 'sales', 'stock']
        const headerTitle = ['Nombre', 'SKU', 'Marca', 'Compras', 'Ventas', 'Stock']
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Detalle de compra y venta',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Get sales
     * */
    async loadMovements () {
      const querySnapshot = await db.collectionGroup('movements').orderBy('createdAt', 'desc').limit(200).get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          oldStock: doc.data().oldStock || 0,
          discount: doc.data().discount || 0,
          product: {}
        }
        // Get product
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === obj.productId) {
            obj.product = this.products[i]
            break
          }
        }
        this.list.push(obj)
      })
    },
    /**
     * Load products
     * @returns {Promise<void>}
     */
    async loadProducts () {
      this.products = []
      this.products.push({
        id: '',
        name: 'Todos',
        sku: 'MMNN',
        brand: {}
      })
      const querySnapshot = await db.collection('products').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let product = {
          id: doc.id,
          name: doc.data().name,
          sku: doc.data().sku + '',
          brand: doc.data().brand
        }
        this.products.push(product)
      })
    },
    /**
     * Get type movements
     */
    getType (type) {
      if (type === 'input') {
        return 'Ingreso manual'
      } else if (type === 'out') {
        return 'Salida manual'
      } else if (type === 'transferIn') {
        return 'Transferencia - Ingreso'
      } else if (type === 'transferOut') {
        return 'Transferencia - Salida'
      } else if (type === 'invoice') {
        return 'Comprobante de compra'
      } else if (type === 'sale') {
        return 'Comprobante de venta'
      } else if (type === 'creditNoteInvoice') {
        return 'Nota de crédito prov.'
      } else if (type === 'creditNote') {
        return 'Nota de crédito'
      }
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          this.sales = []
          this.invoices = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}
          let querySnapshotSales = {}
          let querySnapshotInvoices = {}
          if (this.product.id) {
            querySnapshot = await db.collectionGroup('movements')
                .where('createdAt', '>=', starTimes)
                .where('createdAt', '<=', endTimes)
                .where('productId', '==', this.product.id).orderBy('createdAt', 'desc').get()
          } else {
            querySnapshot = await db.collectionGroup('movements')
                .where('createdAt', '>=', starTimes)
                .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()

            querySnapshotSales = await db.collection('sales')
                .where('issueDate', '>=', starTimes)
                .where('issueDate', '<=', endTimes).get()

            querySnapshotInvoices = await db.collection('invoices')
                .where('dateOfIssue', '>=', starTimes)
                .where('dateOfIssue', '<=', endTimes).get()
          }
          // Movements
          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              oldStock: doc.data().oldStock || 0,
              discount: doc.data().discount || 0,
              product: {}
            }
            // Get product
            for (let i = 0; i < this.products.length; i++) {
              if (this.products[i].id === obj.productId) {
                obj.product = this.products[i]
                break
              }
            }
            this.list.push(obj)
          })

          // Sales
          querySnapshotSales.forEach((doc) => {
            doc.data().detail.forEach((d) => {
              this.sales.push({
                ...d,
                doc: doc.data().serial + '-' + doc.data().sequence
              })
            })
          })

          /// Invoices
          querySnapshotInvoices.forEach((doc) => {
            this.invoices.push(
                ...doc.data().products
            )
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Get warehouses
     * @returns {Promise<void>}
     */
    async loadWarehouses () {
      this.warehouses = []
      this.warehouses.push({
        id: null,
        name: 'Todos'
      })
      const querySnapshot = await db.collection('warehouses').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let warehouse = {
          id: doc.id,
          name: doc.data().name,
          arisale: doc.data().arisale
        }
        this.warehouses.push(warehouse)
      })
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.product = {}
      await this.loadMovements()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    }
  }
}
</script>

<style lang="scss">
#invoice-list {

  .vs-table--tbody-table {
    min-width: 1700px !important;
  }

  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
